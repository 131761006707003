<template>
  <div>
    <v-card outlined class="mb-6">
      <v-card-subtitle class="d-flex justify-start align-center grey lighten-5">
        <div class="flex-grow-1">Details</div>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              depressed
              color="blue lighten-4 blue--text"
              v-on="on"
              @click="$refs.nextOfKinDialog.open(form)"
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Edit Customer's Information</span>
        </v-tooltip>
      </v-card-subtitle>
      <v-divider></v-divider>
      <v-card-text>
        <v-simple-table>
          <tbody>
            <tr>
              <th>Name</th>
              <td>{{ form.next_of_kin_name }}</td>
            </tr>
            <tr>
              <th>Address Line 1</th>
              <td>{{ form.next_of_kin_add1 }}</td>
            </tr>
            <tr>
              <th>Address Line 2</th>
              <td>{{ form.next_of_kin_add2 }}</td>
            </tr>
            <tr>
              <th>City/Region</th>
              <td>{{ form.next_of_kin_city_region }}</td>
            </tr>
            <tr>
              <th>Postcode/Zip Code</th>
              <td>
                {{ form.next_of_kin_postcode }}
              </td>
            </tr>
            <tr>
              <th>Country</th>
              <td>
                {{ form.next_of_kin_country }}
              </td>
            </tr>
            <tr>
              <th>Phone Number</th>
              <td>
                {{ form.next_of_kin_phone_number }}
              </td>
            </tr>
            <tr>
              <th>Email Address</th>
              <td>
                {{ form.next_of_kin_email }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <next-of-kin-dialog ref="nextOfKinDialog" />
  </div>
</template>

<script>
import NextOfKinDialog from "./components/NextOfKinDialog.vue";

export default {
  components: {
    NextOfKinDialog,
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Forms",
          disabled: false,
          to: { name: "module-easylets-applicationforms" },
          exact: true,
        },
      ],
    };
  },

  computed: {
    form() {
      return this.$store.getters["easylets/applicationformsStore/get"];
    },
  },
};
</script>
